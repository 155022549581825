














































































































































































































































import {Component, Vue} from "vue-property-decorator";
import PortalInput from "@/components/common/PortalInput.vue";
import {namespace} from "vuex-class";
import PortalDate from "@/components/common/PortalDate.vue";
import RouteNames from "@/router/RouteNames";
import {WorkspaceType, WorkspaceTypePathElement} from "@/dto/auth/Workspace";
import TerminationModal from "@/components/payroll/business/employments/contractors/TerminationModal.vue";
import TerminationPayloadDTO from "@/components/payroll/business/employments/contractors/TerminationPayloadDTO";
import {ifValid, processError} from "@/utils/ComponentUtils";
import {
	IndividualForeignContractorDTO
} from "@/components/payroll/business/employments/foreignContractors/ForeignContractorPayloads";
import IndividualForeignContractorService
	from "@/components/payroll/business/employments/foreignContractors/IndividualForeignContractorService";
import BTPortalCollapse from "@/components/common/BTPortalCollapse.vue";
import PortalSelect from "@/components/common/PortalSelect.vue";
import SelectOption from "@/components/common/SelectOption";
import Notifications from "@/state/Notifications";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {UpdateTarifficationPayload} from "@/dto/payroll/Tariffication";
import {PAY_PERIOD_TYPE_OPTIONS, PAY_TYPE_OPTIONS, PayPeriodType, PayType} from "@/constants/TarifficationConstants";
import {prettyEnum} from "@/utils/StringUtils";
import {Week} from "@/constants/DateTimeConstants";
import TarifficationService from "@/services/TarifficationService";
import {DateTime} from "luxon";

const AppModule = namespace("App");

@Component({
	computed: {
		DateTime() {
			return DateTime
		},
		PAY_TYPE_OPTIONS() {
			return PAY_TYPE_OPTIONS
		},
		PayPeriodType() {
			return PayPeriodType
		},
		PayType() {
			return PayType
		},
		WorkspaceTypePathElement() {
			return WorkspaceTypePathElement
		},
		WorkspaceType() {
			return WorkspaceType
		},
		PAY_PERIOD_TYPE_OPTIONS() {
			return PAY_PERIOD_TYPE_OPTIONS
		},
	},
	methods: {
		RouteNames() {
			return RouteNames
		}
	},
	components: {PortalCheckbox, PortalSelect, BTPortalCollapse, PortalDate, PortalInput}
})
export default class IndividualForeignContractor extends Vue {

	private message: string | null = null;

	private successful = false;

	private detailsIsOpen = true;

	private contractor = new IndividualForeignContractorDTO();
	private updatePayload: UpdateTarifficationPayload = new UpdateTarifficationPayload();

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	public mounted(): void {
		this.loadContractor().then(
			() => {
				this.updatePayload.payType = this.contractor.tariffication.payType
				this.updatePayload.payPeriodType = this.contractor.tariffication.payPeriodType
				this.updatePayload.rate = this.contractor.tariffication.rate
				this.updatePayload.taxHolderId = this.contractor.id!
				this.updatePayload.overtimeRate = this.contractor.tariffication.overtimeRate
				this.updatePayload.payPeriodStart = this.contractor.tariffication.payPeriodStart;
				this.updatePayload.payPeriodEnd = this.contractor.tariffication.payPeriodEnd;
			}
		);

	}

	private buildWeekDayOptions(): Array<SelectOption> {
		return [
			SelectOption.builder().title(prettyEnum(Week.SUNDAY)).value(Week.SUNDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.MONDAY)).value(Week.MONDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.TUESDAY)).value(Week.TUESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.WEDNESDAY)).value(Week.WEDNESDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.THURSDAY)).value(Week.THURSDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.FRIDAY)).value(Week.FRIDAY).build(),
			SelectOption.builder().title(prettyEnum(Week.SATURDAY)).value(Week.SATURDAY).build(),
		]
	}

	private loadContractor() {
		this.startLoading();
		return IndividualForeignContractorService.getContractorById(Number.parseInt(this.$route.params.contractorId, 10)).then(
			res => {
				this.contractor = res.data;
				this.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private openTerminateModal():void {
		this.$modal.show(
			TerminationModal,
			{
				terminateId: this.contractor.id,
				applyTerminate: (payload: TerminationPayloadDTO) => this.terminate(payload)
			},
			{
				height: "auto"
			}
		)
	}

	private terminate(payload: TerminationPayloadDTO): void {
		this.startLoading();
		IndividualForeignContractorService.terminateContractor(payload).then(
			() => {
				this.successful = true;
				Notifications.success("The contractor's contract has been terminated")
				this.$router.push({name: RouteNames.HR_CONTRACTORS, params: {companyId: String(this.contractor.employer?.id)}})
				this.stopLoading()
			},
			err => processError(err, this)
		)
	}

	private deleteContractor(): void {
		this.startLoading()
		IndividualForeignContractorService.deleteContractor(this.contractor.id as number).then(
			() => {
				this.stopLoading()
				this.$router.push({name: RouteNames.HR_FOREIGN_CONTRACTORS, params: {companyId: String(this.contractor.employer?.id)}})
			},
			err => processError(err, this)
		)
	}

	private updateTariffication(): void {
		this.updatePayload.rate = Number(this.updatePayload.rate.toString().replaceAll(",", "."))
		this.updatePayload.overtimeRate = Number(this.updatePayload.overtimeRate.toString().replaceAll(",", "."))
		ifValid(this, () => {
			this.startLoading();
			TarifficationService.updateTarifficationInfo(this.updatePayload).then(
				() => {
					this.successful = true;
					Notifications.success("The contractor's tariffication has been updated!")
					this.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}

	private update(): void {
		ifValid(this, () => {
			this.startLoading()
			IndividualForeignContractorService.updateContractor(this.contractor).then(
				() => {
					this.successful = true;
					Notifications.success("The contractor's data has been updated")
					this.stopLoading()
				},
				err => processError(err, this)
			)
		})
	}
}

